// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("./assets/bg.png", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;500;700;900&display=swap);"]);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "html {\n    background-image: linear-gradient(rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1)), url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");\n    background-position: 'center';\n    background-size: 'cover';\n    background-repeat: 'no-repeat';\n    background-color: black;\n    font-family: 'Roboto', sans-serif;\n}\n\n#root {\n    height: 100vh;\n}\n\n@media (prefers-reduced-motion: no-preference) {\n    .App-logo {\n        animation: App-logo-zoom 8s ease infinite;\n    }\n}\n\n@keyframes App-logo-zoom {\n    0% {\n        transform: scale(1, 1);\n    }\n\n    50% {\n        transform: scale(1.05, 1.05);\n    }\n\n    100% {\n        transform: scale(1, 1);\n    }\n}\n", "",{"version":3,"sources":["webpack://./src/App.css"],"names":[],"mappings":"AAEA;IACI,kHAAiG;IACjG,6BAA6B;IAC7B,wBAAwB;IACxB,8BAA8B;IAC9B,uBAAuB;IACvB,iCAAiC;AACrC;;AAEA;IACI,aAAa;AACjB;;AAEA;IACI;QACI,yCAAyC;IAC7C;AACJ;;AAEA;IACI;QACI,sBAAsB;IAC1B;;IAEA;QACI,4BAA4B;IAChC;;IAEA;QACI,sBAAsB;IAC1B;AACJ","sourcesContent":["@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;500;700;900&display=swap');\n\nhtml {\n    background-image: linear-gradient(rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1)), url('./assets/bg.png');\n    background-position: 'center';\n    background-size: 'cover';\n    background-repeat: 'no-repeat';\n    background-color: black;\n    font-family: 'Roboto', sans-serif;\n}\n\n#root {\n    height: 100vh;\n}\n\n@media (prefers-reduced-motion: no-preference) {\n    .App-logo {\n        animation: App-logo-zoom 8s ease infinite;\n    }\n}\n\n@keyframes App-logo-zoom {\n    0% {\n        transform: scale(1, 1);\n    }\n\n    50% {\n        transform: scale(1.05, 1.05);\n    }\n\n    100% {\n        transform: scale(1, 1);\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
